export const assets = {
    aUSDC:
    {
        denom: 'uausdc',
        denom_name: 'axlaUSDC',
        decimal: 6,
        allow_decimal: 2,
        fee_decimal: 2,
        type: 'token',
        ethereum:
        {
            tokenAddress: '0x526f0A95EDC3DF4CBDB7bb37d4F7Ed451dB8e369',
            min_deposit: 1,
            min_fee: 0.15, 
        },                              
    },
    ETH:
    {
        denom: 'weth-wei',
        denom_name: 'WETH',
        decimal: 18,
        allow_decimal: 4,
        fee_decimal: 6,
        type: 'token',
        ethereum:
        {
            tokenAddress: '0xc778417E063141139Fce010982780140Aa0cD5Ab',
            contractAddress: '0x8B2f3D3bC6295A723D29957d54C92f961BA7AFa7',
            min_deposit: 0.0001,
            min_fee: 0.00006,
        },                                
    },
    BNB:
    {
        denom: 'wbnb-wei',
        denom_name: 'WBNB',
        decimal: 18,
        allow_decimal: 4,
        fee_decimal: 6,
        type: 'token',
        binance:
        {
            tokenAddress: '0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd',
            contractAddress: '0xc9919b0C8F30d57243366249CdD3f4b11801930F',
            min_deposit: 0.001,
            min_fee: 0.0005,
        },                                
    },     
    AVAX:
    {
        denom: 'wavax-wei',
        denom_name: 'WAVAX',
        decimal: 18,
        allow_decimal: 4,
        fee_decimal: 6,
        type: 'token',
        avalanche:
        {
            tokenAddress: '0xd00ae08403b9bbb9124bb305c09058e32c39a48c',
            contractAddress: '0x7E32f75a439c7492b1dF8AF6c5E97B28aFDe81b0',
            min_deposit: 0.01,
            min_fee: 0.0017,
        },                                
    },
    MATIC:
    {
        denom: 'wmatic-wei',
        denom_name: 'WMATIC',
        decimal: 18,
        allow_decimal: 4,
        fee_decimal: 6,
        type: 'token',
        polygon:
        {
            tokenAddress: '0x9c3C9283D3e44854697Cd22D3Faa240Cfb032889',
            contractAddress: '0xc9919b0C8F30d57243366249CdD3f4b11801930F',
            min_deposit: 1,
            min_fee: 0.1,
        },                                
    },
    FTM:
    {
        denom: 'wftm-wei',
        denom_name: 'WFTM',
        decimal: 18,
        allow_decimal: 4,
        fee_decimal: 6,
        type: 'token',
        fantom:
        {
            tokenAddress: '0x812666209b90344ec8e528375298ab9045c2bd08',
            contractAddress: '0xc9919b0C8F30d57243366249CdD3f4b11801930F',
            min_deposit: 1,
            min_fee: 0.12,
        },                                
    },
    DEV:
    {
        denom: 'wdev-wei',
        denom_name: 'WDEV',
        decimal: 18,
        allow_decimal: 4,
        fee_decimal: 6,
        type: 'token',
        moonbeam:
        {
            tokenAddress: '0x1436ae0df0a8663f18c0ec51d7e2e46591730715',
            contractAddress: '0xc9919b0C8F30d57243366249CdD3f4b11801930F',
            min_deposit: 1,
            min_fee: 0.06,
        },                                
    },
    WETH:
    {
        denom: 'weth-wei',
        denom_name: 'WETH',
        decimal: 18,
        allow_decimal: 4,
        fee_decimal: 6,
        type: 'token',
        ethereum:
        {
            tokenAddress: '0xc778417E063141139Fce010982780140Aa0cD5Ab',
            min_deposit: 0.0001,
            min_fee: 0.00006,
        },
    },
    axlWETH:
    {      
        denom: 'weth-wei',
        denom_name: 'axlWETH',
        decimal: 18,
        allow_decimal: 4,
        fee_decimal: 6,
        type: 'token',          
        binance:
        {
            tokenAddress: '0x03Dc012b7851b7D65592Aebc40a6aF9A171E9315',
            min_deposit: 0.0001,
            min_fee: 0.00006,
        }, 
        avalanche:
        {
            tokenAddress: '0x3613C187b3eF813619A25322595bA5E297E4C08a',
            min_deposit: 0.0001,
            min_fee: 0.00006,
        },               
        polygon:
        {
            tokenAddress: '0xfba15fFF35558fE2A469B96A90AeD7727FE38fAE',
            min_deposit: 0.0001,
            min_fee: 0.00006,
        },
        fantom:
        {
            tokenAddress: '0x930640ef299Bf772f786Cf7E88DA951D76E33168',
            min_deposit: 0.0001,
            min_fee: 0.00006,
        },
        moonbeam:
        {
            tokenAddress: '0xc40Fdaa2cB43C85eAA6D43856df42E7A80669fca',
            min_deposit: 0.0001,
            min_fee: 0.00006,
        },                                       
    },
    WBNB:
    {
        denom: 'wbnb-wei',
        denom_name: 'WBNB',
        decimal: 18,
        allow_decimal: 4,
        fee_decimal: 6,
        type: 'token',
        binance:
        {
            tokenAddress: '0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd',
            min_deposit: 0.001,
            min_fee: 0.0005,
        },                                
    },
    axlWBNB:
    {
        denom: 'wbnb-wei',
        denom_name: 'axlWBNB',
        decimal: 18,
        allow_decimal: 4,
        fee_decimal: 6,
        type: 'token',
        ethereum:
        {
            tokenAddress: '0x653044Df3e853e8FF96c8D9a7Ab7A90E34c4d484',
            min_deposit: 0.001,
            min_fee: 0.0005,
        }, 
        avalanche:
        {
            tokenAddress: '0xd020f566723e8402f925A891605c02ce7AF2477F',
            min_deposit: 0.001,
            min_fee: 0.0005,
        },               
        polygon:
        {
            tokenAddress: '0x55fDE07dEF3261a41fC59B783D27A6357e8A86Df',
            min_deposit: 0.001,
            min_fee: 0.0005,
        },
        fantom:
        {
            tokenAddress: '0x8DA729FC44366eFE36d522B865FeC34653e85F6e',
            min_deposit: 0.001,
            min_fee: 0.0005,
        },
        moonbeam:
        {
            tokenAddress: '0x8d0BBbA567Ae73a06A8678e53Dc7ADD0AF6b7039',
            min_deposit: 0.001,
            min_fee: 0.0005,
        },                                   
    },         
    WAVAX:
    {
        denom: 'wavax-wei',
        denom_name: 'WAVAX',
        decimal: 18,
        allow_decimal: 4,
        fee_decimal: 6,
        type: 'token',
        avalanche:
        {
            tokenAddress: '0xd00ae08403b9bbb9124bb305c09058e32c39a48c',
            min_deposit: 0.01,
            min_fee: 0.0017,
        },                                
    },
    axlWAVAX:
    {
        denom: 'wavax-wei',
        denom_name: 'axlWAVAX',
        decimal: 18,
        allow_decimal: 4,
        fee_decimal: 6,
        type: 'token',
        ethereum:
        {
            tokenAddress: '0x72af7e1e7e0d38bcf033c541598f5a0301d051a5',
            min_deposit: 0.01,
            min_fee: 0.0017,
        }, 
        binance:
        {
            tokenAddress: '0x1B29EC62efC689c462b4E0512457175793cEc9e6',
            min_deposit: 0.01,
            min_fee: 0.0017,
        },               
        polygon:
        {
            tokenAddress: '0x6dd60c05fda1255a44ffaa9a8200b5b179a578d6',
            min_deposit: 0.01,
            min_fee: 0.0017,
        },
        fantom:
        {
            tokenAddress: '0x8776add48553518641a589c39792cc409d4c8b84',
            min_deposit: 0.01,
            min_fee: 0.0017,
        },
        moonbeam:
        {
            tokenAddress: '0x64aae6319934995bf30e67ebbba9750256e07283',
            min_deposit: 0.01,
            min_fee: 0.0017,
        },                                
    },    
    WMATIC:
    {
        denom: 'wmatic-wei',
        denom_name: 'WMATIC',
        decimal: 18,
        allow_decimal: 4,
        fee_decimal: 6,
        type: 'token',
        polygon:
        {
            tokenAddress: '0x9c3C9283D3e44854697Cd22D3Faa240Cfb032889',
            min_deposit: 1,
            min_fee: 0.1,
        },                                
    },
    axlWMATIC:
    {
        denom: 'wmatic-wei',
        denom_name: 'axlWMATIC',
        decimal: 18,
        allow_decimal: 4,
        fee_decimal: 6,
        type: 'token',
        ethereum:
        {
            tokenAddress: '0xEAE61FD42A56F435a913d1570fF301a532d027b2',
            min_deposit: 1,
            min_fee: 0.1,
        }, 
        binance:
        {
            tokenAddress: '0x920fA0DbB65cE928C29103AeC7B5c188bbea2f24',
            min_deposit: 1,
            min_fee: 0.1,
        },               
        avalanche:
        {
            tokenAddress: '0xB923E2374639D0605388D91CFedAfCeCE03Cfd8f',
            min_deposit: 1,
            min_fee: 0.1,
        },
        fantom:
        {
            tokenAddress: '0x3C12d813bb36295A8361C4740A732Bb700df6Db0',
            min_deposit: 1,
            min_fee: 0.1,
        },
        moonbeam:
        {
            tokenAddress: '0xde3dB4FD7D7A5Cc7D8811b7BaFA4103FD90282f3',
            min_deposit: 1,
            min_fee: 0.1,
        },                              
    },    
    WFTM:
    {
        denom: 'wftm-wei',
        denom_name: 'WFTM',
        decimal: 18,
        allow_decimal: 4,
        fee_decimal: 6,
        type: 'token',
        fantom:
        {
            tokenAddress: '0x812666209b90344Ec8e528375298ab9045c2Bd08',
            min_deposit: 1,
            min_fee: 0.12,
        },                                
    },
    axlWFTM:
    {
        denom: 'wftm-wei',
        denom_name: 'axlWFTM',
        decimal: 18,
        allow_decimal: 4,
        fee_decimal: 6,
        type: 'token',
        ethereum:
        {
            tokenAddress: '0xd9774230a31bf49c3d9372eeb55aa10df1807238',
            min_deposit: 1,
            min_fee: 0.12,
        }, 
        binance:
        {
            tokenAddress: '0x90dEcD89a744a0CFbB3cc8DE08A5f3B14875B6C4',
            min_deposit: 1,
            min_fee: 0.12,
        },               
        avalanche:
        {
            tokenAddress: '0xef721babf08a2ee5bccfd2f2a34cbf4dc9a56959',
            min_deposit: 1,
            min_fee: 0.12,
        },
        polygon:
        {
            tokenAddress: '0x62b6f2a4ee6a4801bfcd2056d19c6d71654d2582',
            min_deposit: 1,
            min_fee: 0.12,
        },
        moonbeam:
        {
            tokenAddress: '0x40eebd34ec6cb4c0644a18494365171b1dce97eb',
            min_deposit: 1,
            min_fee: 0.12,
        },                                                            
    },    
    WDEV:
    {
        denom: 'wdev-wei',
        denom_name: 'WDEV',
        decimal: 18,
        allow_decimal: 4,
        fee_decimal: 6,
        type: 'token',
        moonbeam:
        {
            tokenAddress: '0x1436ae0df0a8663f18c0ec51d7e2e46591730715',
            min_deposit: 1,
            min_fee: 0.06,
        },                                
    },
    axlDEV:
    {
        denom: 'wdev-wei',
        denom_name: 'axlDEV',
        decimal: 18,
        allow_decimal: 4,
        fee_decimal: 6,
        type: 'token',
        ethereum:
        {
            tokenAddress: '0xdc6b192efa7ebab24063e20c962e74c88a012d3c',
            min_deposit: 1,
            min_fee: 0.06,
        }, 
        binance:
        {
            tokenAddress: '0xa893Fd868c3159B294f6416F512203be53315fd8',
            min_deposit: 1,
            min_fee: 0.06,
        },               
        avalanche:
        {
            tokenAddress: '0xf58537d9061f7257e44442fb7870a094aae92b43',
            min_deposit: 1,
            min_fee: 0.06,
        },
        polygon:
        {
            tokenAddress: '0xb6a2f51c219a66866263cb18dd41ee6c51b464cb',
            min_deposit: 1,
            min_fee: 0.06,
        },
        fantom:
        {
            tokenAddress: '0xd6f858a1e75e9a06c42dcd86bb876c5e9fcca572',
            min_deposit: 1,
            min_fee: 0.06,
        },                                                                                           
    },     
    WBTC:
    {
        denom: 'wbtc-satoshi',
        denom_name: 'axlWBTC',
        decimal: 8,
        allow_decimal: 4,
        fee_decimal: 6,
        type: 'token',
        ethereum:
        {
            tokenAddress: '0x5db5f7d211FA88266Fb316948da0D45798e5a22f',
            min_deposit: 0.002,
            min_fee: 0.00102,
        },                             
    },
    axlATOM:
    {
        denom: 'uatom',
        denom_name: 'axlATOM',
        decimal: 6,
        allow_decimal: 4,
        fee_decimal: 6,
        type: 'token',
        ethereum:
        {
            tokenAddress: '0xace65699f78e6d2237d087f3c6e68e22146af9ee',
            min_deposit: 10,
            min_fee: 3.1,
        },
        binance:
        {
            tokenAddress: '0x00F0F14872c4deD7343fD8352D75D13922D8CA0E',
            min_deposit: 10,
            min_fee: 3.1,
        },              
        avalanche:
        {
            tokenAddress: '0x7c2a1defa77004c7e65c396b77c9a3e429b5dd57',
            min_deposit: 10,
            min_fee: 3.1,
        },
        polygon:
        {
            tokenAddress: '0xe8c0f3ca0dc4dec95b7ebfe419cc5f8dd302249a',
            min_deposit: 10,
            min_fee: 3.1,    
        },
        fantom:
        {
            tokenAddress: '0xac570e9df00c22d9ca7ef559cfe1bab7a1d8fffa',
            min_deposit: 10,
            min_fee: 3.1,     
        },
        moonbeam:
        {
            tokenAddress: '0xbaf40419323acc80c2f94aa531221a9cb639d77e',
            min_deposit: 10,
            min_fee: 3.1,     
        },                                        
    },
    axlaUSDC:
    {
        denom: 'uausdc',
        denom_name: 'axlaUSDC',
        decimal: 6,
        allow_decimal: 2,
        fee_decimal: 2,
        type: 'token',
        binance:
        {
            tokenAddress: '0x00F0F14872c4deD7343fD8352D75D13922D8CA0E',
            min_deposit: 1,
            min_fee: 0.15, 
        },              
        avalanche:
        {
            tokenAddress: '0x7c2a1defa77004c7e65c396b77c9a3e429b5dd57',
            min_deposit: 1,
            min_fee: 0.15, 
        },
        polygon:
        {
            tokenAddress: '0xe8c0f3ca0dc4dec95b7ebfe419cc5f8dd302249a',
            min_deposit: 1,
            min_fee: 0.15,    
        },
        fantom:
        {
            tokenAddress: '0xac570e9df00c22d9ca7ef559cfe1bab7a1d8fffa',
            min_deposit: 1,
            min_fee: 0.15,    
        },
        moonbeam:
        {
            tokenAddress: '0xbaf40419323acc80c2f94aa531221a9cb639d77e',
            min_deposit: 1,
            min_fee: 0.15,   
        },                               
    },                      
};
import React from 'react';

const Smart_Contracts = () => {
return (
	<div className='forms smart_contracts'>
	<h3>Smart Contract Details & Links</h3>
    <h4>CosmosBridge.app Smart Contracts (Testnet)</h4>
		<p>Ethereum : <a href="https://ropsten.etherscan.io/address/0x8B2f3D3bC6295A723D29957d54C92f961BA7AFa7#code" target="_blank">0x8B2f3D3bC6295A723D29957d54C92f961BA7AFa7</a></p>
		<p>Binance : <a href="https://testnet.bscscan.com/address/0xc9919b0C8F30d57243366249CdD3f4b11801930F" target="_blank">0xc9919b0C8F30d57243366249CdD3f4b11801930F</a></p>        
        <p>Avalanche : <a href="https://testnet.snowtrace.io/address/0x7e32f75a439c7492b1df8af6c5e97b28afde81b0" target="_blank">0x7e32f75a439c7492b1df8af6c5e97b28afde81b0</a></p>
        <p>Polygon : <a href="https://mumbai.polygonscan.com/address/0xc9919b0c8f30d57243366249cdd3f4b11801930f" target="_blank">0xc9919b0c8f30d57243366249cdd3f4b11801930f</a></p>
        <p>Fantom : <a href="https://testnet.ftmscan.com/address/0xc9919b0c8f30d57243366249cdd3f4b11801930f" target="_blank">0xc9919b0c8f30d57243366249cdd3f4b11801930f</a></p>
        <p>Moonbeam : <a href="https://moonbeam.moonscan.io/address/0x1B7239aB106C7bAc8176607A33F57F6f02007aDe#contracts" target="_blank">0x1B7239aB106C7bAc8176607A33F57F6f02007aDe</a></p>


	</div>
);
};

export default Smart_Contracts;

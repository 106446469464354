/*

    "aurora",
    "Avalanche",
    "Axelarnet",
    "binance",
    "cosmoshub",
    "crescent",
    "Ethereum",
    "Fantom",
    "juno",
    "kujira",
    "Moonbeam",
    "osmosis",
    "osmosis-2",
    "osmosis-3",
    "osmosis-4",
    "Polygon",
    "sei",
    "terra",
    "terra-2"

*/    

export const chains = {
    ethereum: 
    {
        evmChain: 3,
        evmExplorer: 'https://ropsten.etherscan.io/tx/',
        image: '../images/ethereum.png',
    },
    binance: 
    {
        evmChain: 97,
        evmExplorer: 'https://testnet.bscscan.com/tx/',
        image: '../images/bnbchain.png',
    },    
    avalanche: 
    {
        evmChain: 43113,
        evmExplorer: 'https://testnet.snowtrace.io/tx/',
        image: '../images/avalanche.png',
    },
    polygon: 
    {
        evmChain: 80001,
        evmExplorer: 'https://mumbai.polygonscan.com/tx/',
        image: '../images/polygon.png',
    },
    fantom: 
    {
        evmChain: 4002,
        evmExplorer: 'https://testnet.ftmscan.com/tx/',
        image: '../images/fantom.png',
    },
    moonbeam: 
    {
        evmChain: 1287,
        evmExplorer: 'https://moonbase.moonscan.io/tx/',
        image: '../images/moonbeam.png',
    },    
    kujira:
    {
        keplrChainID: 'harpoon-4',
        image: '../images/kujira.png',
    },
    axelar:
    {
        keplrChainID: 'axelar-testnet-lisbon-3',
        image: '../images/axelar.svg',
    },
    terra:
    {
        keplrChainID: 'pisco-1',
        image: '../images/luna.svg',
    },
    sei:
    {
        keplrChainID: 'atlantic-1',
        image: '../images/sei.png',
    },
    osmosis:
    {
        keplrChainID: 'osmo-test-4',
        image: '../images/osmosis.png',
    },                                         
};
export const chain_assets =
    {
        ethereum_assets:
        [                      
            {id: 'aUSDC', name: <span className="yellow"><img src="../images/usdc.png"></img> aUSDC</span>},
            {id: 'ETH', name: <span className="yellow"><img src="../images/ethereum.png"></img> ETH</span>},
            {id: 'WETH', name: <span className="yellow"><img src="../images/ethereum.png"></img> WETH</span>},
            {id: 'WBTC', name: <span className="yellow"><img src="../images/wbtc.png"></img> WBTC</span>},
            {id: 'axlWBNB', name: <span className="yellow"><img src="../images/bnbchain.png"></img> axlWBNB</span>},
            {id: 'axlWAVAX', name: <span className="yellow"><img src="../images/avalanche.png"></img> axlWAVAX</span>},
            {id: 'axlWMATIC', name: <span className="yellow"><img src="../images/polygon.png"></img> axlWMATIC</span>},
            {id: 'axlWFTM', name: <span className="yellow"><img src="../images/fantom.png"></img> axlWFTM</span>},
            {id: 'axlWDEV', name: <span className="yellow"><img src="../images/moonbeam.png"></img> axlWDEV</span>},                                                            
        ],
        binance_assets:
        [                      
            {id: 'axlaUSDC', name: <span className="yellow"><img src="../images/usdc.png"></img> axlaUSDC</span>},
            {id: 'BNB', name: <span className="yellow"><img src="../images/bnbchain.png"></img> BNB</span>},
            {id: 'WBNB', name: <span className="yellow"><img src="../images/bnbchain.png"></img> WBNB</span>},            
            {id: 'axlWETH', name: <span className="yellow"><img src="../images/ethereum.png"></img> axlWETH</span>},
            {id: 'axlWAVAX', name: <span className="yellow"><img src="../images/avalanche.png"></img> axlWAVAX</span>},
            {id: 'axlWMATIC', name: <span className="yellow"><img src="../images/polygon.png"></img> axlWMATIC</span>},
            {id: 'axlWFTM', name: <span className="yellow"><img src="../images/fantom.png"></img> axlWFTM</span>},
            {id: 'axlWDEV', name: <span className="yellow"><img src="../images/moonbeam.png"></img> axlWDEV</span>},                                                            
        ],        
        avalanche_assets:
        [                     
            {id: 'axlaUSDC', name: <span className="yellow"><img src="../images/usdc.png"></img> axlaUSDC</span>},
            {id: 'AVAX', name: <span className="yellow"><img src="../images/avalanche.png"></img> AVAX</span>},
            {id: 'WAVAX', name: <span className="yellow"><img src="../images/avalanche.png"></img> WAVAX</span>},            
            {id: 'axlWETH', name: <span className="yellow"><img src="../images/ethereum.png"></img> axlWETH</span>},
            {id: 'axlWBNB', name: <span className="yellow"><img src="../images/bnbchain.png"></img> axlWBNB</span>},
            {id: 'axlWMATIC', name: <span className="yellow"><img src="../images/polygon.png"></img> axlWMATIC</span>},
            {id: 'axlWFTM', name: <span className="yellow"><img src="../images/fantom.png"></img> axlWFTM</span>},
            {id: 'axlWDEV', name: <span className="yellow"><img src="../images/moonbeam.png"></img> axlWDEV</span>},  
        ],
        polygon_assets:
        [                      
            {id: 'axlaUSDC', name: <span className="yellow"><img src="../images/usdc.png"></img> axlaUSDC</span>},           
            {id: 'WMATIC', name: <span className="yellow"><img src="../images/polygon.png"></img> WMATIC</span>},            
            {id: 'axlWETH', name: <span className="yellow"><img src="../images/ethereum.png"></img> axlWETH</span>},
            {id: 'axlWBNB', name: <span className="yellow"><img src="../images/bnbchain.png"></img> axlWBNB</span>},
            {id: 'axlWAVAX', name: <span className="yellow"><img src="../images/avalanche.png"></img> axlWAVAX</span>},
            {id: 'axlWFTM', name: <span className="yellow"><img src="../images/fantom.png"></img> axlWFTM</span>},
            {id: 'axlWDEV', name: <span className="yellow"><img src="../images/moonbeam.png"></img> axlWDEV</span>},  
        ],
        fantom_assets:
        [                     
            {id: 'axlaUSDC', name: <span className="yellow"><img src="../images/usdc.png"></img> axlaUSDC</span>},
            {id: 'FTM', name: <span className="yellow"><img src="../images/fantom.png"></img> FTM</span>},
            {id: 'WFTM', name: <span className="yellow"><img src="../images/fantom.png"></img> WFTM</span>},            
            {id: 'axlWETH', name: <span className="yellow"><img src="../images/ethereum.png"></img> axlWETH</span>},
            {id: 'axlWBNB', name: <span className="yellow"><img src="../images/bnbchain.png"></img> axlWBNB</span>},
            {id: 'axlWAVAX', name: <span className="yellow"><img src="../images/avalanche.png"></img> axlWAVAX</span>},
            {id: 'axlWMATIC', name: <span className="yellow"><img src="../images/polygon.png"></img> axlWMATIC</span>},
            {id: 'axlWDEV', name: <span className="yellow"><img src="../images/moonbeam.png"></img> axlWDEV</span>},  
        ],
        moonbeam_assets:
        [                      
            {id: 'axlaUSDC', name: <span className="yellow"><img src="../images/usdc.png"></img> axlaUSDC</span>},
            {id: 'DEV', name: <span className="yellow"><img src="../images/moonbeam.png"></img> DEV</span>},
            {id: 'WDEV', name: <span className="yellow"><img src="../images/moonbeam.png"></img> WDEV</span>},              
            {id: 'axlWETH', name: <span className="yellow"><img src="../images/ethereum.png"></img> axlWETH</span>},
            {id: 'axlWBNB', name: <span className="yellow"><img src="../images/bnbchain.png"></img> axlWBNB</span>},
            {id: 'axlWAVAX', name: <span className="yellow"><img src="../images/avalanche.png"></img> axlWAVAX</span>},
            {id: 'axlWMATIC', name: <span className="yellow"><img src="../images/polygon.png"></img> axlWMATIC</span>},
            {id: 'axlWFTM', name: <span className="yellow"><img src="../images/fantom.png"></img> axlWFTM</span>},
        ],                                 
    };
import React, { useState, useEffect } from 'react';
//import useState from 'react-usestateref'
import styled from "styled-components";
import { chain_assets } from '../components/Assets/chain_assets';

const DropDownContainer = styled("div")`
  width: 100%;
  margin: 0 auto;
`;



const DropDownListContainer = styled("div")``;

const DropDownList = styled("ul")`
overflow-y: scroll;
height:200px;  
visibility: visible;
position: relative;
border-radius: 5px;
background-color: rgba(0, 0, 0, 0.5);
box-shadow: rgb(255 255 255 / 50%) 0px 0px 1px;
cursor: pointer;
text-indent: 10px;
width: 300px;
font-size: 20px;
font-weight: bold;
  padding: 10px;
  margin: 0 auto 0 auto;
  background-color: #161721;
  z-index: 10;
  border-radius: 0 0 0.5rem 0.5rem;
  box-shadow: 0 0 0 1px #607d8b40, 2px 2px 10px #16172159;
  &:first-child {
    padding-top: 0.8em;
  }
`;

const ListItem = styled("li")`
  list-style: none;
  margin-bottom: 0.8em;
`;

const selection = [
	{ asset: '', from: '', to: '' }
];

var asset = chain_assets['ethereum_assets'];

const from = [
	{id: 'ethereum', name: <span className="yellow"><img src="../images/ethereum.png"></img> Ethereum</span>},
	{id: 'binance', name: <span className="yellow"><img src="../images/bnbchain.png"></img> BNB Chain</span>},	
	{id: 'avalanche', name: <span className="yellow"><img src="../images/avalanche.png"></img> Avalanche</span>},
	{id: 'polygon', name: <span className="yellow"><img src="../images/polygon.png"></img> Polygon</span>},
	{id: 'fantom', name: <span className="yellow"><img src="../images/fantom.png"></img> Fantom</span>},
	{id: 'moonbeam', name: <span className="yellow"><img src="../images/moonbeam.png"></img> Moonbeam</span>},
];

const to = [
	{id: 'axelar', name: <span className="yellow"><img src="../images/axelar.png"></img> Axelar</span>},	
	{id: 'osmosis', name: <span className="yellow"><img src="../images/osmosis.png"></img> Osmosis</span>},	
	{id: 'kujira', name: <span className="yellow"><img src="../images/kujira.png"></img> Kujira</span>},
	{id: 'sei', name: <span className="yellow"><img src="../images/sei.png"></img> Sei</span>},
	{id: 'terra', name: <span className="yellow"><img src="../images/luna.svg"></img> Terra</span>},	
];

const Home = () => {

	const [isOpenAsset, setIsOpenAsset] = useState(false);
	const [isOpenFrom, setIsOpenFrom] = useState(false);
	const [isOpenTo, setIsOpenTo] = useState(false);

	const [selectedAsset, setSelectedAsset] = useState(null);
	const [selectedFrom, setSelectedFrom] = useState(null);
	const [selectedTo, setSelectedTo] = useState(null);
	
	const [valueAsset, setValueAsset] = useState('aUSDC');
	const [valueFrom, setValueFrom] = useState('ethereum');
	const [valueTo, setValueTo] = useState('sei');

	const [startAsset, setStartAsset] = useState({value: <span className="yellow"><img src="../images/usdc.png"></img> aUSDC</span>});

	const togglingAsset = () => {
		setIsOpenAsset(!isOpenAsset);
		setIsOpenFrom(false);
		setIsOpenTo(false);			
	}

	const togglingFrom = () => {
		setIsOpenAsset(false);
		setIsOpenFrom(!isOpenFrom);
		setIsOpenTo(false);	
	}
	const togglingTo = () => { 
		setIsOpenAsset(false);
		setIsOpenFrom(false);		
		setIsOpenTo(!isOpenTo);
	}

	const onAssetClicked = value => () => {
		setSelectedAsset(value.name);
		setValueAsset(value.id);
		setIsOpenAsset(false);
	};		
  
	const onFromClicked = value => () => {
		if(value.id === 'ethereum')
		{
			togglingAsset();
			asset = chain_assets['ethereum_assets'];
			setIsOpenAsset(false);
			setStartAsset(chain_assets['ethereum_assets'][0].name);
			setValueAsset(chain_assets['ethereum_assets'][0].id);
			setSelectedAsset(chain_assets['ethereum_assets'][0].name);
		}
		else if(value.id === 'binance')
		{
			togglingAsset();
			asset = chain_assets['binance_assets'];
			setIsOpenAsset(false);
			setStartAsset(chain_assets['binance_assets'][0].name);
			setValueAsset(chain_assets['binance_assets'][0].id);
			setSelectedAsset(chain_assets['binance_assets'][0].name);
		}		
		else if(value.id === 'avalanche')
		{
			togglingAsset();
			asset = chain_assets['avalanche_assets'];
			setIsOpenAsset(false);
			setStartAsset(chain_assets['avalanche_assets'][0].name);
			setValueAsset(chain_assets['avalanche_assets'][0].id);
			setSelectedAsset(chain_assets['avalanche_assets'][0].name);
		}
		else if(value.id === 'polygon')
		{
			togglingAsset();
			asset = chain_assets['polygon_assets'];
			setIsOpenAsset(false);
			setStartAsset(chain_assets['polygon_assets'][0].name);
			setValueAsset(chain_assets['polygon_assets'][0].id);
			setSelectedAsset(chain_assets['polygon_assets'][0].name);
		}
		else if(value.id === 'fantom')
		{
			togglingAsset();
			asset = chain_assets['fantom_assets'];
			setIsOpenAsset(false);
			setStartAsset(chain_assets['fantom_assets'][0].name);
			setValueAsset(chain_assets['fantom_assets'][0].id);
			setSelectedAsset(chain_assets['fantom_assets'][0].name);
		}
		else if(value.id === 'moonbeam')
		{
			togglingAsset();
			asset = chain_assets['moonbeam_assets'];
			setIsOpenAsset(false);
			setStartAsset(chain_assets['moonbeam_assets'][0].name);
			setValueAsset(chain_assets['moonbeam_assets'][0].id);
			setSelectedAsset(chain_assets['moonbeam_assets'][0].name);
		}						
		setSelectedFrom(value.name);
		setValueFrom(value.id);
		setIsOpenFrom(false);
	};	

	const onToClicked = value => () => {
		setSelectedTo(value.name);
		setValueTo(value.id);
		setIsOpenTo(false);
	};
	
    const getURL = () => {

		selection.asset = valueAsset;
		selection.from = valueFrom;
		selection.to = valueTo;
		selection.type = 'token';

		if(valueAsset === 'AVAX')
			selection.type = 'native';

		window.location.assign('/#/execute/' + selection.asset + '-' + selection.from + '-' + selection.to);
	}

	return (
	 
		<div>
		<div className="youtube"><a href='https://youtu.be/1qHAyPxvEIo' target='_blank'>Checkout Our Youtube Tutorial</a></div>
		<div className='forms contact'>

		
		<p className="transfer">Select Source & Destination</p>
		
		<DropDownContainer>
		<div className="myBlock">
		<span className="homeFrom">Source :</span>
		  <div className="DropDownHeader" onClick={togglingFrom}>
			{selectedFrom || <span className="yellow"><img src="../images/ethereum.png"></img> Ethereum</span>}
			<span className="triangle_down1"></span>
		  </div>
		  {isOpenFrom && (
			<DropDownListContainer>
			  <div className="DropDownList hanekp">
				{from.map(option => (
				  <ListItem onClick={onFromClicked(option)} key={option.id}>
					{option.name}
				  </ListItem>
				))}
			  </div>
			</DropDownListContainer>
		  )}
		  </div>
		</DropDownContainer>

		<span className="triangle_down2"></span>

		<DropDownContainer>
		<div className="myBlock">
		  <span className="homeTo">Destination :</span>
		  <div className="DropDownHeader" onClick={togglingTo}>
			{selectedTo || <span className="yellow"><img src="../images/sei.png"></img> Sei</span>}
			<span className="triangle_down1"></span>
		  </div>
		  {isOpenTo && (
			<DropDownListContainer>
			<div className="DropDownList hanekp">
				{to.map(option => (
				  <ListItem onClick={onToClicked(option)} key={option.id}>
					{option.name}
				  </ListItem>
				))}
			  </div>
			</DropDownListContainer>
		  )}
		  </div>
		</DropDownContainer>

		<hr></hr>

		<p className="transfer">Select the Asset to Transfer</p>
		
		<DropDownContainer>
		  <div className="DropDownHeader" onClick={togglingAsset}>
			{selectedAsset || startAsset.value}
			<span className="triangle_down1"></span>
		  </div>
		  {isOpenAsset && (
			<DropDownListContainer>
			<div className="DropDownList hanekp">
				{asset.map(option => (
				  <ListItem onClick={onAssetClicked(option)} key={option.id}>
					{option.name}
				  </ListItem>
				))}
			  </div>
			</DropDownListContainer>
		  )}
		</DropDownContainer>

		<p><button onClick={getURL} className="btn btn-primary go">Go</button></p>
		</div>
		</div>

	);
};

export default Home;

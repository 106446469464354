import React from 'react';

const Footer = () => {
return (
	<div className='footer'>
        <a className="fa fa-home block" aria-hidden="true" href="/"></a>
        <a href="/#/how_it_works">How it Works?</a>
        <a href="/#/fees">Fees?</a>
        <a href="/#/smart_contracts">Smart Contracts</a>
        <a href="/#/contact">Contact Info</a>
	</div>
);
};

export default Footer;

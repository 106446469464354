import React from 'react';

const How_It_Works = () => {
return (
	<div className='forms2 how'>
	<h3>How does this Actually Work?</h3>
        <p>Axelar provides an effortless and convenient Bridge to transfer Assets from popular EVM Chains such as Ethereum, Avalanche, Polygon, Fantom, etc.</p>
        <p>However, if you want to transfer native coins such as ETH, BNB, AVAX, etc, you need to swap them to Wrapped version of the Coins such as WETH, WBNB, WAVAX, etc and then use Axelar to transfer these to Cosmos chains.</p>
        <p>We are developing this App to simplify this process so users can transfer their native coins directly with one transaction. When you use our App, the Transfer goes through the following steps.</p>

        <hr></hr>

        <h4>Frontend Process:</h4>

        <p>Example: Transfer 1 ETH from Ethereum to Sei.</p>
        
        <p></p>
        <p>1. Connect both Metamask and Keplr. This will fetch your Ethereum address from Metamask with ETH balance and your Sei address from Keplr, respectively.</p>
        <p>2. Generate a one-time Deposit Address on Ethereum Blockchain. Axelar generates this in the backend.</p>
        <p>3. Initiate the transfer by clicking on Execute button.</p>

        <hr></hr>

        <h4>The following steps take place in the backend.</h4>

        <p>1. When You Initiate the transfer, 1 ETH is converted to wrapped version of ETH (WETH) through our Smart Contact on Ethereum Blockchain.</p>
        <p>2. WETH is directly deposited to the One Time Deposit address generated above.</p>
        <p>3. Axelar automatically picks up this WETH deposit and transfers it to Sei.</p>
        <p>4. This transfer can take around 15 minutes to complete.</p>
        <p></p>

        <p>If you have initiated a transfer and did not receive your WETH at Sei in less than 20 minutes, please contact us through Discord and we will help you.</p>

	</div>
);
};

export default How_It_Works;

export const config = [

    {
        id: "sei", values: 
        {
            "chainId": "atlantic-1",
            "chainName": "sei-testnet",
            "rpc": "https://sei-testnet-rpc.synergynodes.com",
            "rest": "https://sei-testnet.synergynodes.com",
            "bip44": {
                "coinType": "118"
            },
            "coinType": "118",
            "bech32Config": {
                "bech32PrefixAccAddr": "sei",
                "bech32PrefixAccPub": "seipub",
                "bech32PrefixValAddr": "seivaloper",
                "bech32PrefixValPub": "seivaloperpub",
                "bech32PrefixConsAddr": "seivalcons",
                "bech32PrefixConsPub": "seivalconspub"
            },
            "currencies": [
                {
                    "coinDenom": "SEI",
                    "coinMinimalDenom": "usei",
                    "coinDecimals": "6",
                    "coinGeckoId": "unknown"
                }
            ],
            "feeCurrencies": [
                {
                    "coinDenom": "SEI",
                    "coinMinimalDenom": "usei",
                    "coinDecimals": "6",
                    "coinGeckoId": "unknown"
                }
            ],
            "stakeCurrency": {
                "coinDenom": "SEI",
                "coinMinimalDenom": "usei",
                "coinDecimals": "6",
                "coinGeckoId": "unknown"
            },
            "gasPriceStep": {
                "low": 0.01,
                "average": 0.025,
                "high": 0.03
            }
        },
    },
    {
        id: "kujira", values: 
        {
            "chainId": "harpoon-4",
            "chainName": "kujira-testnet",
            "rpc": "https://test-rpc-kujira.mintthemoon.xyz",
            "rest": "https://test-lcd-kujira.mintthemoon.xyz",
            "bip44": {
                "coinType": "118"
            },
            "coinType": "118",
            "bech32Config": {
                "bech32PrefixAccAddr": "kujira",
                "bech32PrefixAccPub": "kujirapub",
                "bech32PrefixValAddr": "kujiravaloper",
                "bech32PrefixValPub": "kujiravaloperpub",
                "bech32PrefixConsAddr": "kujiravalcons",
                "bech32PrefixConsPub": "kujiravalconspub"
            },
            "currencies": [
                {
                    "coinDenom": "KUJI",
                    "coinMinimalDenom": "ukuji",
                    "coinDecimals": "6",
                    "coinGeckoId": "kujira"
                }
            ],
            "feeCurrencies": [
                {
                    "coinDenom": "KUJI",
                    "coinMinimalDenom": "ukuji",
                    "coinDecimals": "6",
                    "coinGeckoId": "kujira"
                }
            ],
            "stakeCurrency": {
                "coinDenom": "KUJI",
                "coinMinimalDenom": "ukuji",
                "coinDecimals": "6",
                "coinGeckoId": "kujira"
            },
            "gasPriceStep": {
                "low": 0.01,
                "average": 0.025,
                "high": 0.03
            }
        }
    }, 
    {
        id: "axelar", values: 
        {
            "chainId": "axelar-testnet-lisbon-3",
            "chainName": "axelar-testnet-lisbon-3",
            "rpc": "https://rpc-testnet-axelar.synergynodes.com",
            "rest": "https://lcd-testnet-axelar.synergynodes.com",
            "bip44": {
                "coinType": "118"
            },
            "coinType": "118",
            "bech32Config": {
                "bech32PrefixAccAddr": "axelar",
                "bech32PrefixAccPub": "axelarpub",
                "bech32PrefixValAddr": "axelarvaloper",
                "bech32PrefixValPub": "axelarvaloperpub",
                "bech32PrefixConsAddr": "axelarvalcons",
                "bech32PrefixConsPub": "axelarvalconspub"
            },
            "currencies": [
                {
                    "coinDenom": "AXL",
                    "coinMinimalDenom": "uaxl",
                    "coinDecimals": "6",
                    "coinGeckoId": "unknown"
                }
            ],
            "feeCurrencies": [
                {
                    "coinDenom": "AXL",
                    "coinMinimalDenom": "uaxl",
                    "coinDecimals": "6",
                    "coinGeckoId": "unknown"
                }
            ],
            "stakeCurrency": {
                "coinDenom": "AXL",
                "coinMinimalDenom": "uaxl",
                "coinDecimals": "6",
                "coinGeckoId": "unknown"
            },
            "gasPriceStep": {
                "low": 0.01,
                "average": 0.025,
                "high": 0.03
            }
        }
    },
    {
        id: "terra", values: 
        {
            "chainId": "pisco-1",
            "chainName": "terra-pisco-1",
            "rpc": "https://terra-testnet-rpc.polkachu.com",
            "rest": "https://pisco-lcd.terra.dev",
            "bip44": {
                "coinType": "505"
            },
            "coinType": "505",
            "bech32Config": {
                "bech32PrefixAccAddr": "terra",
                "bech32PrefixAccPub": "terrapub",
                "bech32PrefixValAddr": "terravaloper",
                "bech32PrefixValPub": "terravaloperpub",
                "bech32PrefixConsAddr": "terravalcons",
                "bech32PrefixConsPub": "terravalconspub"
            },
            "currencies": [
                {
                    "coinDenom": "LUNA",
                    "coinMinimalDenom": "uluna",
                    "coinDecimals": "6",
                    "coinGeckoId": "unknown"
                }
            ],
            "feeCurrencies": [
                {
                    "coinDenom": "LUNA",
                    "coinMinimalDenom": "uluna",
                    "coinDecimals": "6",
                    "coinGeckoId": "unknown"
                }
            ],
            "stakeCurrency": {
                "coinDenom": "LUNA",
                "coinMinimalDenom": "uluna",
                "coinDecimals": "6",
                "coinGeckoId": "unknown"
            },
            "gasPriceStep": {
                "low": 0.01,
                "average": 0.025,
                "high": 0.03
            }
        }
    },        
    
];